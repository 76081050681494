﻿import {$, $$, get, postDataText, initForm} from "@/Utils"
import { initFavorites } from "@/ProductModule";


function initScroll() {
  const accordion = $(".comparison .accordion") as HTMLElement;
  if (accordion) {
    window.addEventListener("scroll", () => {
      setStickyVisibility(accordion);
    });
  }
}

function setStickyVisibility(accordion: HTMLElement) {
  const comparisonSticky = $(".comparison-sticky") as HTMLElement;
  if (!accordion || !comparisonSticky) {
    return;
  }
  comparisonSticky.style.transform = "translateY(" + (window.pageYOffset - comparisonSticky.parentElement.offsetTop) + "px)"

  const comparisonPosition = accordion.offsetTop;
  if (window.pageYOffset >= comparisonPosition) {
    comparisonSticky.classList.add("comparison-sticky--active");
  } else {
    comparisonSticky.classList.remove("comparison-sticky--active");
  }
}

function showDifferences() {
  $$(".comparison-table__row").forEach(x => {
    if (x.dataset.hasDifferences === "True") {
      x.classList.add("comparison-table__row--difference");
    }
  });
};



function initDifferences() {
  $$(".difference").forEach(x => {
    (x as Element).addEventListener("change", function () {
      const isChecked = this.checked;
      $$(".difference").forEach(y => {
        (y as HTMLInputElement).checked = isChecked;
      });
      if (isChecked) {
        showDifferences();
      } else {
        $$(".comparison-table__row--difference").forEach(y => {
          (y as Element).classList.remove("comparison-table__row--difference");
        });
      }
    });
  });
}

function initRemoveClick() {

  $$("[data-comparison-remove-id]").forEach(x => {
    (x as Element).addEventListener("click", function () {
      const removeId = x.getAttribute("data-comparison-remove-id");
      const productIds = GetProductIds();
      const data = { removeId: parseInt(removeId), productIds: productIds };
      const callback = function (resp: string) {
        get("comparison-container").innerHTML = resp;
        const accordion = $(".comparison .accordion") as HTMLElement;
        setStickyVisibility(accordion);
        if (productIds) {
          RemoveProductIdFromUrl(productIds, removeId);
          $$("[data-lang]").forEach(x => {
            const url = x.getAttribute("href");
            x.setAttribute("href", RemoveProductIdFromUrl(productIds, removeId, url));
          });
        }
        initComparison();
      }
      postDataText(window.config.removeFromComparisonUrl, data, callback);
    });
  });

}

function RemoveProductIdFromUrl(productIds: string, removeId: string, url?: string) {
    const separator = '-';
    let idList = productIds.split(separator);
    idList.splice(idList.indexOf(removeId), 1);
    var newIds = idList.join(separator);
    if (url) {
        return url.replace(productIds, newIds);
    }
    else {
        history.replaceState(null, null, location.href.replace(productIds, newIds));
    }
}

function GetProductIds() {
    const urlPieces = window.location.href.split('/');
    return urlPieces[urlPieces.length - 1];
}

function sendComparisonCallback(resp: Response) {
    return resp.text().then(x => { get("sendComparisonForm").innerHTML = x; });
}

function initComparison() {
    initScroll();
    initRemoveClick();
    initDifferences();
    initForm("sendComparisonForm", sendComparisonCallback);
    initFavorites();
}

window.addEventListener("load", () => {
    initComparison();
});